<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title="false">
    <a-spin :spinning="loading">
      <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
        <a-form :form="form" v-if="isShowForm">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="标题"
            :validateStatus="checkParams.titleStatus"
            :help="checkParams.titleStatusMsg"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入标题"
              v-decorator="['title', {initialValue: queryParam.title, rules: [{required: true, validator: titleCheck, validateTrigger: 'change'}]}]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="内容"
            :validateStatus="checkParams.contentStatus"
            :help="checkParams.contentStatusMsg"
          >
            <a-textarea
              style="width: 80%;"
              :rows="5"
              v-decorator="['content', {initialValue: queryParam.content, rules: [{required: true, validator: contentCheck, validateTrigger: 'change'}]}]"
              placeholder="请输入内容"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="图片"
          >
            <a-upload
              :fileList="fileList"
              listType="picture-card"
              :disabled="isImageUpload"
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
              @preview="handlePreview"
              :remove="removeImage"
              accept="image/*"
            >
              <div>
                <span><a-icon :type="uploading ? 'loading' : 'picture'" /></span>
                <span>上传</span>
              </div>
            </a-upload>
            <a-modal :visible="previewInfo.previewVisible" :title="previewInfo.previewTitle" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewInfo.previewImage" />
            </a-modal>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="公告类型"
            :validateStatus="checkParams.noticeTypeStatus"
            :help="checkParams.noticeTypeStatusMsg"
          >
            <a-select
              style="width: 200px;"
              v-model="queryParam.noticeType"
              placeholder="请选择"
              @change="changeNoticeType"
            >
              <a-select-option :key="0" :value="-1">请选择</a-select-option>
              <a-select-option v-for="item in typeItem" :key="item.itemId" :value="item.itemId">
                {{ item.itemName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="发送方式"
            :validateStatus="checkParams.sendTypeStatus"
            :help="checkParams.sendTypeStatusMsg"
          >
            <a-select
              style="width: 200px;"
              v-model="queryParam.sendType"
              placeholder="请选择"
              @change="changeSendType"
              :disabled="isEite"
            >
              <a-select-option :key="0" :value="-1">请选择</a-select-option>
              <a-select-option v-for="item in sendTypeList" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="发布时间"
          >
            <div>
              <a-radio-group
                v-model="queryParam.isPublishTime"
                style="width: 100%"
              >
                <a-radio :value="0">立刻</a-radio>
                <a-radio :value="1">定时</a-radio>
              </a-radio-group>
            </div>
            <div v-if="queryParam.isPublishTime === 1">
              <a-date-picker
                v-model="queryParam.publishTime"
                style="width: 200px;"
                showTime
                valueFormat="YYYY-MM-DD HH:mm:ss"
              />
            </div>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="过期时间"
            v-if="queryParam.sendType <= 0"
          >
            <a-date-picker
              v-model="queryParam.expiredTime"
              style="width: 200px;"
              showTime
              valueFormat="YYYY-MM-DD HH:mm:ss"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="备注"
          >
            <a-textarea
              style="width: 80%;"
              :rows="5"
              v-model="queryParam.remark"
              placeholder="请输入备注"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="发送范围"
          >
            <a-radio-group
              v-model="queryParam.sendRangeTab"
              style="width: 100%"
              :disabled="isEite"
            >
              <a-radio :value="1">按区域</a-radio>
              <a-radio :value="2">全员</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="区域范围"
            v-if="queryParam.sendRangeTab === 1"
          >
            <a-spin :spinning="tabLoading">
              <a-tabs :activeKey="queryParam.sendRangeType" @change="changeTab" type="card">
                <a-tab-pane tab="按小区" :key="1">
                  <a-transfer
                    :showSearch="true"
                    :dataSource="communityList"
                    :targetKeys="communityTargetKeys"
                    :render="item => item.title"
                    :oneWay="true"
                    @change="changeCommunity"
                    :listStyle="{ width: '400px', height: '400px' }"
                    pagination
                  >
                  </a-transfer>
                </a-tab-pane>
                <a-tab-pane tab="按楼宇" :key="2" v-if="!isEite">
                  <a-transfer
                    :showSearch="true"
                    :dataSource="buildinglist"
                    :targetKeys="buildingTargetKeys"
                    :render="item => item.title"
                    :oneWay="true"
                    @change="changeBuilding"
                    :listStyle="{ width: '400px', height: '400px' }"
                    pagination
                  ></a-transfer>
                </a-tab-pane>
                <a-tab-pane tab="按单元" :key="3" v-if="!isEite">
                  <a-transfer
                    :showSearch="true"
                    :dataSource="unitList"
                    :targetKeys="unitTargetKeys"
                    :render="item => item.title"
                    :oneWay="true"
                    @change="changeUnit"
                    :listStyle="{ width: '400px', height: '400px' }"
                    pagination
                  ></a-transfer>
                </a-tab-pane>
                <a-tab-pane tab="按个人" :key="4" v-if="!isEite">
                  <a-row :gutter="20">
                    <a-col :md="5" :sm="24">
                      <a-select
                        allow-clear
                        style="width:170px;"
                        v-model="searchQueryParam.communityId"
                        placeholder="请选择小区"
                        @change="changeRegionCommunity"
                      >
                        <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                        <a-select-option v-for="item in regionCommunity" :key="item.keyId" :value="item.id">
                          {{ item.communityName }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :md="5" :sm="24">
                      <a-select
                        allow-clear
                        style="width:170px"
                        v-model="searchQueryParam.buildingId"
                        placeholder="楼宇"
                        @change="changeRegionbuilding"
                      >
                        <a-select-option :key="0" :value="0">选择楼宇</a-select-option>
                        <a-select-option v-for="item in regionBuilding" :key="item.keyId" :value="item.id">
                          {{ item.buildingName }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :md="5" :sm="24">
                      <a-select
                        allow-clear
                        style="width:170px;"
                        v-model="searchQueryParam.unitId"
                        placeholder="选择单元"
                        @change="changeRegionUnit"
                      >
                        <a-select-option :key="0" :value="0">选择单元</a-select-option>
                        <a-select-option v-for="item in regionUnit" :key="item.keyId" :value="item.id">
                          {{ item.unitName }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :md="5" :sm="24">
                      <a-select
                        allow-clear
                        style="width:170px"
                        v-model="searchQueryParam.roomId"
                        placeholder="选择房屋"
                      >
                        <a-select-option :key="0" :value="0">选择房屋</a-select-option>
                        <a-select-option v-for="item in regionHouse" :key="item.keyId" :value="item.id">
                          {{ item.houseName }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                  <a-row :gutter="20" style="margin-top: 10px;">
                    <a-col :md="5" :sm="24">
                      <a-input placeholder="用户姓名" style="width:170px" v-model="searchQueryParam.realName" allow-clear/>
                    </a-col>
                    <a-col :md="5" :sm="24">
                      <a-input placeholder="手机号" style="width:170px" v-model="searchQueryParam.telephone" allow-clear/>
                    </a-col>
                    <a-col :md="5" :sm="24">
                      <a-button
                        type="primary"
                        @click="memberListApi"
                        :disabled="searchDisabled"
                        :loading="searchLoading"
                      >
                        搜索
                      </a-button>
                    </a-col>
                    <a-col :md="5" :sm="24">
                    </a-col>
                  </a-row>
                  <a-row :gutter="24" style="margin-top: 10px;">
                    <a-col :md="12" :sm="24">
                      <a-card title="搜索用户">
                        <div style="height: 200px">
                          <span v-for="(row, index) in memberList" :key="index">
                            <a-checkbox
                              :value="row.keyId"
                              :checked="userArr.indexOf(row.keyId) > -1 ? true : false"
                              @change="userChange($event, row)"
                            >
                              {{ row.realName }}
                            </a-checkbox>
                          </span>
                        </div>
                      </a-card>
                    </a-col>
                    <a-col :md="12" :sm="24">
                      <a-card title="已选用户">
                        <div style="height: 200px">
                          <span v-for="(row, index) in userTargetKeys" :key="index">
                            <a-tag :closable="true" @close="delUserSelect(row, $event)">{{ userNameList['u_' + row] }}</a-tag>
                          </span>
                        </div>
                      </a-card>
                    </a-col>
                  </a-row>
                </a-tab-pane>
              </a-tabs>
            </a-spin>
          </a-form-item>
          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
          >
            <a-button
              type="primary"
              @click="gotoBack"
              style="margin-right: 10px;"
            >
              返回
            </a-button>
            <a-button
              type="primary"
              @click="saveNoticeApi"
              :disabled="saveDisabled || queryParam.checkStatus === 1"
              :loading="saveLoading"
            >
              提交
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </page-header-wrapper>
</template>

<script>

import { noticeCondition, noticeInfo, saveNotice } from '@/api/notice'
import { allCommunityList } from '@/api/community'
import { allUnitlist, regionUnit } from '@/api/unit'
import { allBuildinglist, regionBuilding } from '@/api/building'
import { regionHouse } from '@/api/house'
import { allMemberList } from '@/api/user'
import { addImagelibrary, getToken } from '@/api/upload'
import OSS from 'ali-oss'

export default {
  name: 'NoticeInfo',
  data () {
    return {
      previewInfo: {
        previewVisible: false,
        previewTitle: '',
        previewImage: ''
      },
      isImageUpload: false,
      uploading: false,
      fileList: [],
      uploadImageInfo: {
        fileName: '',
        url: '',
        width: 0,
        height: 0,
        size: 0,
        bucket: 'dadaojianqi-syue',
        type: 0,
        transcoding: 0
      },
      tokenInfo: {
        'region': '',
        'AccessKeyId': '',
        'AccessKeySecret': '',
        'SecurityToken': '',
        'bucket': '',
        'domain': ''
      },
      form: this.$form.createForm(this, { name: 'addNoticeFrom' }),
      loading: false,
      saveDisabled: false,
      saveLoading: false,
      tabLoading: false,
      searchDisabled: false,
      searchLoading: false,
      labelCol: {
        lg: { span: 2 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 17 },
        sm: { span: 17 }
      },
      queryParam: {
        keyId: 0,
        id: '',
        title: '',
        content: '',
        publishTime: '',
        expiredTime: '',
        remark: '',
        sendType: -1,
        noticeType: -1,
        checkStatus: 0,
        status: 1,
        isPublishTime: 0,
        sendRangeType: 1,
        communityTargetKeys: [],
        buildingTargetKeys: [],
        unitTargetKeys: [],
        userTargetKeys: [],
        userNameList: {},
        imageList: [],
        imageListData: [],
        sendRangeTab: 1
      },
      searchQueryParam: {
        pageNo: 1,
        pageSize: 100000000,
        realName: '',
        telephone: '',
        communityId: 0,
        buildingId: 0,
        unitId: 0,
        roomId: 0
      },
      checkParams: {
        titleStatus: 'success',
        titleStatusMsg: null,
        contentStatus: 'success',
        contentStatusMsg: null,
        noticeTypeStatus: 'success',
        noticeTypeStatusMsg: null,
        sendTypeStatus: 'success',
        sendTypeStatusMsg: null
      },
      dataParams: {},
      errors: [],
      checkStatusList: [],
      sendTypeList: [],
      typeItem: [],
      isTab: 1,
      communityList: [],
      communityTargetKeys: [],
      unitList: [],
      unitTargetKeys: [],
      buildinglist: [],
      buildingTargetKeys: [],
      regionCommunity: [],
      regionBuilding: [],
      regionUnit: [],
      regionHouse: [],
      memberList: [],
      userArr: [],
      userTargetKeys: [],
      userNameList: {},
      noticeInfoData: {
        communityTargetKeys: [],
        buildingTargetKeys: [],
        unitTargetKeys: [],
        userTargetKeys: [],
        userNameList: {}
      },
      gotoType: 1,
      noticeInfoKey: '',
      isShowForm: true,
      isEite: false
    }
  },
  created () {
    this.isShowForm = true
    this.getTokenApi()
    this.noticeConditionApi()
    this.allCommunityListApi()
    if (this.$route.query.noticeId !== 0 && this.$route.query.noticeId !== undefined && this.$route.query.noticeId !== '') {
      this.queryParam.keyId = parseInt(this.$route.query.noticeId)
    }

    if (this.$route.query.type !== 0 && this.$route.query.type !== undefined && this.$route.query.type !== '') {
      this.gotoType = parseInt(this.$route.query.type)
    }

    if (this.queryParam.keyId > 0) {
      this.noticeInfoApi(this.queryParam.keyId)
    }
  },
  watch: {
    $route (val) {
      if (val.name === 'GridNoticeInfo' || val.name === 'GridVerifyNoticeInfo') {
        this.isShowForm = false
        this.$nextTick(function () {
          this.isShowForm = true
        })
        this.getTokenApi()
        this.noticeConditionApi()
        this.allCommunityListApi()
        if (this.$route.query.noticeId !== 0 && this.$route.query.noticeId !== undefined && this.$route.query.noticeId !== '') {
          this.queryParam.keyId = parseInt(this.$route.query.noticeId)
        }

        if (this.$route.query.type !== 0 && this.$route.query.type !== undefined && this.$route.query.type !== '') {
          this.gotoType = parseInt(this.$route.query.type)
        }

        if (this.queryParam.keyId > 0) {
          this.noticeInfoApi(this.queryParam.keyId)
        }
      }
    }
  },
  methods: {
    gotoBack () {
      if (this.gotoType === 2) {
        this.$router.push({ path: '/notice/verify-list' })
      } else {
        this.$router.push({ path: '/notice/notice-list' })
      }
    },
    handleCancel () {
      this.previewInfo.previewVisible = false
      this.previewInfo.previewImage = ''
      this.previewInfo.previewTitle = ''
    },
    handlePreview (e) {
      this.previewInfo.previewImage = e.url
      this.previewInfo.previewTitle = e.name
      this.previewInfo.previewVisible = true
        console.log('handlePreview', e)
    },
    removeImage (e) {
      console.log('removeImage', e)
      this.fileList.forEach((item, index) => {
        if (item.uid === e.uid) {
          this.fileList.splice(index, 1)
        }
      })

      var indexa = this.queryParam.imageList.indexOf(e.uid)
      if (indexa > -1) {
        this.queryParam.imageList.splice(indexa, 1)
      }
      return true
    },
    getTokenApi () {
      const _this = this
      getToken().then((res) => {
        if (res.errorCode === 0) {
          _this.tokenInfo = res.result
          console.log('tokenInfo', _this.tokenInfo)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    addImagelibraryApi () {
      const _this = this
      addImagelibrary(_this.uploadImageInfo).then((res) => {
        if (res.errorCode === 0) {
          const imgRow = {
            uid: res.result.id,
            name: res.result.fileName,
            status: 'done',
            url: res.result.url
          }
          _this.fileList.push(imgRow)
          _this.queryParam.imageList.push(res.result.id)
          console.log('fileList', _this.fileList)
          _this.$message.success('上传成功')
          console.log('addImagelibrary', res.result)
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.isImageUpload = false
        _this.uploading = false
      }).catch((err) => {
        _this.isImageUpload = false
        _this.uploading = false
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    async customRequest (e) {
      console.log('customRequest', e)
      const _this = this
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: _this.tokenInfo.region,
        // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
        accessKeyId: _this.tokenInfo.AccessKeyId,
        accessKeySecret: _this.tokenInfo.AccessKeySecret,
        stsToken: _this.tokenInfo.SecurityToken,
        // 填写Bucket名称，例如examplebucket。
        bucket: _this.tokenInfo.bucket,
        secure: true
      })

      const headers = {
        // 指定该Object被下载时的网页缓存行为。
        'Cache-Control': 'no-cache',
        // 指定该Object被下载时的名称。
        'Content-Disposition': _this.uploadImageInfo.fileName,
        // 指定该Object被下载时的内容编码格式。
        'Expires': 1000,
        'Content-Encoding': 'utf-8',
        // 指定Object标签，可同时设置多个标签。
        'x-oss-tagging': 'image=1',
        // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
        'x-oss-forbid-overwrite': 'true'
      }

      const options = {
        // 获取分片上传进度、断点和返回值。
        progress: (p, cpt, res) => {
          console.log(p)
        },
        // 设置并发上传的分片数量。
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
        headers
      }
      console.log(client, options)

      try {
        const result = await client.multipartUpload(_this.uploadImageInfo.fileName, e.file, {
          ...options
        })

        if (result.name) {
          console.log(_this.tokenInfo.domain + result.name)
          _this.uploadImageInfo.url = _this.tokenInfo.domain + result.name
          _this.addImagelibraryApi()
        } else {
          _this.$message.error('上传失败')
          _this.isImageUpload = false
          _this.uploading = false
        }
        console.log(result)
      } catch (e) {
        _this.isImageUpload = false
        _this.uploading = false
        console.log(e)
      }
    },
    beforeUpload (file, fileList) {
      console.log('beforeUpload', file)
      if (this.isImageUpload) {
        this.$message.error('有其他图片正常上传，请稍后在操作')
        return false
      }
      console.log(file)
      var fileArr = file.name.split('.')
      var fileType = fileArr[fileArr.length - 1]

      var myDate = new Date()
      var year = myDate.getFullYear()
      var month = myDate.getMonth() + 1
      var day = myDate.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }

      if (day >= 0 && day <= 9) {
        day = '0' + day
      }

      var randomStr = this.getRandomStr(14)
      var keyimg = 'image/' + year + '/' + month + day + '/' + randomStr + '.' + fileType
      console.log(keyimg)
      this.uploadImageInfo.fileName = keyimg
      this.uploadImageInfo.size = Math.ceil(file.size / 1000)
      this.isImageUpload = true
      this.uploading = true
      return true
    },
    getRandomStr (len) {
      var str = ''
      for (var i = 0; i < len; i++) {
        str += Math.random().toString(36).substr(2)
      }
      return str.substr(0, len)
    },
    noticeInfoApi (keyId) {
      const _this = this
      _this.loading = true
      noticeInfo({ keyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          _this.queryParam = res.result
          _this.queryParam = JSON.parse(JSON.stringify(_this.queryParam))
          _this.noticeInfoData = res.result
          _this.communityTargetKeys = res.result.communityTargetKeys
          _this.unitTargetKeys = res.result.unitTargetKeys
          _this.buildingTargetKeys = res.result.buildingTargetKeys
          _this.userTargetKeys = res.result.userTargetKeys
          _this.userNameList = res.result.userNameList
          _this.fileList = res.result.imageListData
          _this.noticeInfoKey = res.timestamp
          console.log(_this.noticeInfoKey)
          if (_this.queryParam.checkStatus === 1) {
            _this.isImageUpload = true
          }
          if (_this.queryParam.sendRangeType === 2) {
            _this.allBuildinglistApi()
          } else if (_this.queryParam.sendRangeType === 3) {
            _this.allUnitlistApi()
          } else if (_this.queryParam.sendRangeType === 4) {
            _this.regionCommunityApi()
          }

          if (_this.queryParam.noticeType === '005-4') {
            _this.isEite = true
          } else {
            _this.isEite = false
          }
        }
        _this.loading = false
      }).catch((err) => {
        _this.$message.error('系统错误')
        _this.loading = false
        console.log(err)
      })
    },
    noticeConditionApi () {
      const _this = this
      noticeCondition().then((res) => {
        if (res.errorCode === 0) {
          _this.checkStatusList = res.result.checkStatusList
          _this.sendTypeList = res.result.sendTypeList
          _this.typeItem = res.result.typeItem
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    allCommunityListApi () {
      const _this = this
      if (_this.communityList.length > 0) {
        return false
      }
      _this.tabLoading = true
      allCommunityList().then((res) => {
        if (res.errorCode === 0) {
          _this.communityList = res.result.data
        }
        _this.tabLoading = false
      }).catch((err) => {
        console.log(err)
        _this.tabLoading = false
        _this.$message.error('系统错误')
      })
    },
    changeCommunity (targetKeys, direction, moveKeys) {
      this.communityTargetKeys = targetKeys
      console.log('changeCommunity', targetKeys)
      console.log('changeCommunity', direction)
      console.log('changeCommunity', moveKeys)
    },
    allUnitlistApi () {
      const _this = this
      if (_this.unitList.length > 0) {
        return false
      }
      _this.tabLoading = true
      allUnitlist().then((res) => {
        if (res.errorCode === 0) {
          _this.unitList = res.result.data
        }
        _this.tabLoading = false
      }).catch((err) => {
        console.log(err)
        _this.tabLoading = false
        _this.$message.error('系统错误')
      })
    },
    changeUnit (targetKeys, direction, moveKeys) {
      this.unitTargetKeys = targetKeys
      console.log('changeUnit', targetKeys)
      console.log('changeUnit', direction)
      console.log('changeUnit', moveKeys)
    },
    allBuildinglistApi () {
      const _this = this
      if (_this.buildinglist.length > 0) {
        return false
      }
      _this.tabLoading = true
      allBuildinglist().then((res) => {
        if (res.errorCode === 0) {
          _this.buildinglist = res.result.data
        }
        _this.tabLoading = false
      }).catch((err) => {
        _this.tabLoading = false
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    changeBuilding (targetKeys, direction, moveKeys) {
      this.buildingTargetKeys = targetKeys
      console.log('changeBuilding', targetKeys)
      console.log('changeBuilding', direction)
      console.log('changeBuilding', moveKeys)
    },
    titleCheck (rule, value, callback) {
      const _this = this
      _this.queryParam.title = value.trim()
      if (_this.queryParam.title !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入标题'
        callback(new Error('请输入标题'))
      }
    },
    contentCheck (rule, value, callback) {
      const _this = this
      _this.queryParam.content = value.trim()
      if (_this.queryParam.content !== '') {
        _this.checkParams.contentStatus = 'success'
        _this.checkParams.contentStatusMsg = null
      } else {
        _this.checkParams.contentStatus = 'error'
        _this.checkParams.contentStatusMsg = '请输入内容'
        callback(new Error('请输入内容'))
      }
    },
    changeNoticeType () {
      const _this = this
      console.log(_this.queryParam.noticeType)
      if (_this.queryParam.noticeType === -1) {
        _this.checkParams.noticeTypeStatus = 'error'
        _this.checkParams.noticeTypeStatusMsg = '请选择公告类型'
      } else {
        _this.checkParams.noticeTypeStatus = 'success'
        _this.checkParams.noticeTypeStatusMsg = null
      }

      if (_this.queryParam.noticeType === '005-4') {
        _this.queryParam.sendType = 0
        _this.queryParam.sendRangeTab = 1
        _this.queryParam.sendRangeType = 1
        _this.isEite = true
      } else {
        _this.isEite = false
      }
    },
    changeSendType () {
      const _this = this
      if (_this.queryParam.sendType === -1) {
        _this.checkParams.sendTypeStatus = 'error'
        _this.checkParams.sendTypeStatusMsg = '请选择发送方式'
      } else {
        _this.checkParams.sendTypeStatus = 'success'
        _this.checkParams.sendTypeStatusMsg = null
      }
    },
    changeTab (e) {
      this.isTab = parseInt(e)
      this.queryParam.sendRangeType = this.isTab
      if (this.isTab === 1) {
        this.allCommunityListApi()
        this.communityTargetKeys = this.noticeInfoData.communityTargetKeys
      } else if (this.isTab === 2) {
        this.allBuildinglistApi()
        this.buildingTargetKeys = this.noticeInfoData.buildingTargetKeys
      } else if (this.isTab === 3) {
        this.allUnitlistApi()
        this.unitTargetKeys = this.noticeInfoData.unitTargetKeys
      } else {
        this.regionCommunityApi()
        this.userTargetKeys = this.noticeInfoData.userTargetKeys
        this.userNameList = this.noticeInfoData.userNameList
      }
    },
    changeRegionCommunity (e) {
      this.searchQueryParam.communityId = e
      this.regionBuildingApi(this.searchQueryParam.communityId)
      this.searchQueryParam.buildingId = 0
      this.regionUnit = []
      this.searchQueryParam.unitId = 0
      this.regionHouse = []
      this.searchQueryParam.roomId = 0
    },
    changeRegionbuilding (e) {
      this.searchQueryParam.buildingId = e
      this.regionUnitApi(this.searchQueryParam.buildingId)
      this.searchQueryParam.unitId = 0
      this.regionHouse = []
      this.searchQueryParam.roomId = 0
    },
    changeRegionUnit (e) {
      this.searchQueryParam.unitId = e
      this.regionHouseApi(this.searchQueryParam.unitId)
      this.searchQueryParam.roomId = 0
    },
    regionBuildingApi (communityId) {
      const _this = this
      regionBuilding({ communityId: communityId }).then((res) => {
        if (res.errorCode === 0) {
          _this.regionBuilding = res.result.data
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionUnitApi (buildingId) {
      const _this = this
      regionUnit({ buildingId: buildingId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.regionUnit = res.result.data
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionHouseApi (unitId) {
      const _this = this
      regionHouse({ unitId: unitId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.regionHouse = res.result.data
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionCommunityApi () {
      const _this = this
      _this.tabLoading = true
      allCommunityList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionCommunity = res.result.data
        }
        _this.tabLoading = false
      }).catch((err) => {
        _this.tabLoading = false
        console.log(err)
        _this.$message.error('系统错误')
      })
    },
    memberListApi () {
      const _this = this
      if (_this.searchQueryParam.realName === '' && this.searchQueryParam.telephone === '' && this.searchQueryParam.roomId === 0) {
        _this.$message.error('请选择房屋或输入用户名或手机号搜索用户')
        return false
      }
      _this.searchDisabled = true
      _this.searchLoading = true
      allMemberList(_this.searchQueryParam).then((res) => {
        if (res.errorCode === 0) {
          _this.memberList = res.result.data
        }
        _this.searchDisabled = false
        _this.searchLoading = false
      }).catch((err) => {
        console.log(err)
        _this.searchDisabled = false
        _this.searchLoading = false
        _this.$message.error('系统错误')
      })
    },
    userChange (e, row) {
      console.log('userChange', e, row)
      var userId = parseInt(e.target.value)
      if (e.target.checked) {
        this.userArr.push(userId)
        var index = this.userTargetKeys.indexOf(userId)
        if (index === -1) {
          this.userTargetKeys.push(userId)
        }
        this.userNameList['u_' + userId] = row.realName
        console.log('userNameList', this.userNameList)
        // this.userTargetKeys = JSON.parse(JSON.stringify(this.userTargetKeys))
      }
    },
    delUserSelect (row, e) {
      e.preventDefault()
      console.log('delUserSelect', e, row)
      var userId = parseInt(row)
      var index = this.userArr.indexOf(userId)
      if (index > -1) {
        this.userArr.splice(index, 1)
      }

      var index1 = this.userTargetKeys.indexOf(userId)
      if (index1 > -1) {
        this.userTargetKeys.splice(index1, 1)
      }

      this.userNameList['u_' + userId] = ''
      console.log(this.userTargetKeys)
    },
    saveNoticeApi () {
      const _this = this
      if (_this.queryParam.title !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入标题'
        return false
      }

      if (_this.queryParam.content !== '') {
        _this.checkParams.contentStatus = 'success'
        _this.checkParams.contentStatusMsg = null
      } else {
        _this.checkParams.contentStatus = 'error'
        _this.checkParams.contentStatusMsg = '请输入内容'
        return false
      }

      if (_this.queryParam.noticeType === -1) {
        _this.checkParams.noticeTypeStatus = 'error'
        _this.checkParams.noticeTypeStatusMsg = '请选择公告类型'
        return false
      } else {
        _this.checkParams.noticeTypeStatus = 'success'
        _this.checkParams.noticeTypeStatusMsg = null
      }

      if (_this.queryParam.sendType === -1) {
        _this.checkParams.sendTypeStatus = 'error'
        _this.checkParams.sendTypeStatusMsg = '请选择发送方式'
        return false
      } else {
        _this.checkParams.sendTypeStatus = 'success'
        _this.checkParams.sendTypeStatusMsg = null
      }

      _this.saveDisabled = true
      _this.saveLoading = true
      _this.queryParam.communityTargetKeys = _this.communityTargetKeys
      _this.queryParam.buildingTargetKeys = _this.buildingTargetKeys
      _this.queryParam.unitTargetKeys = _this.unitTargetKeys
      _this.queryParam.userTargetKeys = _this.userTargetKeys
      saveNotice(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          if (_this.queryParam.keyId > 0) {
            _this.$message.success('编辑成功')
          } else {
            _this.$message.success('添加成功')
            _this.$router.push({ path: '/notice/notice-info?noticeId=' + res.result.data.keyId })
          }
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.saveDisabled = false
        _this.saveLoading = false
      }).catch((err) => {
        console.log(err)
        _this.saveDisabled = false
        _this.saveLoading = false
        _this.$message.error('系统错误')
      })
    }
  }
}
</script>
